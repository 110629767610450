import React from 'react';

const Terms = () => {
  return (
    <div className="bg-black text-gray-300 max-w-7xl mx-auto py-12 px-6 mt-32">
      <h1 className="text-4xl font-bold text-white mb-8">Terms and Conditions</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">1. Definitions</h2>
        <p className="mb-2">
          <strong>Company, We, Us, or Our:</strong> Refers to DashPoint, the operator of the Services.
        </p>
        <p className="mb-2">
          <strong>User, You, or Your:</strong> Refers to any individual accessing or using the Services.
        </p>
        <p className="mb-2">
          <strong>Content:</strong> Includes text, images, graphics, software, and any other materials provided through the Services.
        </p>
        <p>
          <strong>Personal Data:</strong> Any information relating to an identified or identifiable natural person as defined under the UK General Data Protection Regulation (UK GDPR).
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">2. Acceptance of Terms</h2>
        <p>
          By using DashPoint, you agree to comply with and be legally bound by these Terms and our Privacy Policy. These Terms apply to all users of the Services, including users who are contributors of content.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">3. Eligibility</h2>
        <p className="mb-2">
          <strong>Age Requirement:</strong> You must be at least 16 years old to use our Services. Users under 18 must have parental or guardian consent.
        </p>
        <p>
          <strong>Legal Capacity:</strong> By agreeing to these Terms, you represent that you have the legal capacity to enter into these agreements.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">4. Description of Services</h2>
        <p>
          DashPoint is a running application that sends notifications to users' iPhones three hours before a DashPoint event begins. Users have three hours to reach the dashpoint and must complete the route to earn DashPoints. Routes are created and managed by DashPoint.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">5. User Accounts</h2>
        <p className="mb-2">
          <strong>Registration:</strong> To access certain features, you may need to create an account. You agree to provide accurate, current, and complete information during registration.
        </p>
        <p className="mb-2">
          <strong>Account Security:</strong> You are responsible for safeguarding your account credentials. Notify us immediately of any unauthorized use.
        </p>
        <p>
          <strong>Account Termination:</strong> We reserve the right to suspend or terminate your account for any reason, including violation of these Terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">6. User Responsibilities</h2>
        <p className="mb-2">
          <strong>Compliance:</strong> You agree to comply with all applicable laws and regulations when using the Services.
        </p>
        <p className="mb-2">
          <strong>Accurate Information:</strong> Ensure that all information provided to us is accurate and up-to-date.
        </p>
        <p>
          <strong>Respectful Conduct:</strong> Maintain a respectful and safe environment for all users.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">7. Prohibited Activities</h2>
        <p>You agree not to:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>Use the Services for any unlawful purpose or in violation of these Terms.</li>
          <li>Disrupt or interfere with the integrity or performance of the Services.</li>
          <li>Misrepresent your identity or affiliation with any person or entity.</li>
          <li>Attempt to gain unauthorized access to our systems or data.</li>
          <li>Share content that is defamatory, obscene, harmful, or infringing on intellectual property rights.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">8. Privacy Policy</h2>
        <p>
          Your use of the Services is also governed by our{' '}
          <a href="/privacy-policy" className="text-blue-400 underline">
            Privacy Policy
          </a>
          . Please review our Privacy Policy to understand how we collect, use, and protect your Personal Data.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">9. Intellectual Property</h2>
        <p className="mb-2">
          <strong>Ownership:</strong> All Content and intellectual property rights in the Services are owned by DashPoint or its licensors.
        </p>
        <p className="mb-2">
          <strong>License to Use:</strong> We grant you a limited, non-exclusive, non-transferable license to use the Services for personal, non-commercial purposes.
        </p>
        <p>
          <strong>Restrictions:</strong> You may not reproduce, modify, distribute, or create derivative works from the Content without our explicit permission.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">10. User-Generated Content</h2>
        <p className="mb-2">
          <strong>Responsibility:</strong> You are solely responsible for any Content you submit or share through the Services.
        </p>
        <p className="mb-2">
          <strong>License Grant:</strong> By submitting Content, you grant DashPoint a worldwide, royalty-free, sublicensable license to use, reproduce, and distribute your Content as necessary to provide the Services.
        </p>
        <p>
          <strong>Prohibited Content:</strong> You must not upload or share Content that violates these Terms or any applicable laws.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">11. Disclaimers and Limitation of Liability</h2>
        <p className="mb-2">
          <strong>"As Is" Basis:</strong> The Services are provided on an "as is" and "as available" basis without warranties of any kind, either express or implied.
        </p>
        <p className="mb-2">
          <strong>No Guarantee:</strong> We do not guarantee the accuracy, reliability, or availability of the Services.
        </p>
        <p className="mb-2">
          <strong>Limitation of Liability:</strong> To the fullest extent permitted by law, DashPoint shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of the Services.
        </p>
        <p>
          <strong>Maximum Liability:</strong> Our total liability to you for any claim arising out of or relating to these Terms or your use of the Services shall not exceed the amount you have paid us in the past twelve months.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">12. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless DashPoint, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees, arising out of or in any way connected with your access to or use of the Services, your violation of these Terms, or your infringement of any intellectual property or other rights of any person or entity.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">13. Termination</h2>
        <p>
          We may terminate or suspend your access to the Services immediately, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Services will cease immediately.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">14. Governing Law and Jurisdiction</h2>
        <p>
          These Terms are governed by and construed in accordance with the laws of England and Wales. You agree to submit to the exclusive jurisdiction of the courts of England and Wales in any dispute arising out of or in connection with these Terms or your use of the Services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">15. Dispute Resolution</h2>
        <p className="mb-2">
          <strong>Informal Resolution:</strong> We encourage you to contact us directly to resolve any disputes informally.
        </p>
        <p className="mb-2">
          <strong>Arbitration:</strong> If a dispute cannot be resolved informally, it may be resolved through binding arbitration in accordance with the Arbitration Act 1996.
        </p>
        <p>
          <strong>Exclusions:</strong> Certain disputes, such as those involving intellectual property rights, may not be subject to arbitration.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">16. Changes to Terms</h2>
        <p>
          We reserve the right to modify or replace these Terms at any time. We will notify you of any changes by updating the "Last Updated" date at the top of these Terms. Your continued use of the Services after any changes constitutes acceptance of the new Terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">17. Severability</h2>
        <p>
          If any provision of these Terms is found to be unenforceable or invalid under any applicable law, that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions will remain in full force and effect.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">18. Entire Agreement</h2>
        <p>
          These Terms, together with our Privacy Policy, constitute the entire agreement between you and DashPoint regarding your use of the Services and supersede all prior agreements, understandings, and representations.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">19. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <p className="mt-2">
          <strong>Email:</strong>{' '}
          <a href="mailto:support@dashpoint.co.uk" className="text-blue-400 underline">
            support@dashpoint.co.uk
          </a>
        </p>
        <p className="mt-2">
          <strong>Address:</strong> [Your Company Address]
        </p>
        <p className="mt-2">
          <strong>Phone:</strong> [Your Contact Number]
        </p>
      </section>

      {/* Additional Legal Considerations */}
      <section className="mb-8">
        <h2 className="text-3xl font-bold text-white mb-6">Additional Legal Considerations</h2>

        <h3 className="text-2xl font-semibold text-white mb-4">1. Liability Waivers and Disclaimers</h3>
        <p className="mb-2">
          <strong>Assumption of Risk:</strong> Participating in DashPoint events involves inherent risks. Users engage at their own risk.
        </p>
        <p className="mb-2">
          <strong>Health Disclaimer:</strong> Users are advised to consult with a healthcare professional before participating if they have any medical conditions or concerns.
        </p>

        <h3 className="text-2xl font-semibold text-white mb-4">2. Insurance Information</h3>
        <p>
          DashPoint maintains appropriate insurance coverage, including:
        </p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>General Liability Insurance covering claims related to bodily injury or property damage.</li>
          <li>Event Insurance for specialized coverage related to physical activities and events.</li>
        </ul>

        <h3 className="text-2xl font-semibold text-white mb-4">3. Safety and Compliance</h3>
        <p className="mb-2">
          <strong>Route Safety:</strong> DashPoint ensures that all routes are safe and comply with local laws.
        </p>
        <p className="mb-2">
          <strong>Emergency Protocols:</strong> Clear procedures are in place for emergencies during events, including access to medical assistance if needed.
        </p>

        <h3 className="text-2xl font-semibold text-white mb-4">4. Location Data and Geofencing Compliance</h3>
        <p className="mb-2">
          <strong>Consent for Location Tracking:</strong> Explicit consent is obtained for collecting and using location data.
        </p>
        <p className="mb-2">
          <strong>Data Security:</strong> Strong security measures are implemented to protect location data from unauthorized access or breaches.
        </p>
        <p>
          <strong>Usage of Geofencing:</strong> Geofencing is used within the app in compliance with relevant regulations.
        </p>

        <h3 className="text-2xl font-semibold text-white mb-4">5. Accessibility Compliance</h3>
        <p className="mb-2">
          DashPoint is committed to making the Services accessible to all users, complying with the Equality Act 2010.
        </p>
        <p className="mb-2">
          <strong>Accessibility Features:</strong> The app and website include features that enhance accessibility for users with disabilities.
        </p>
        <p>
          <strong>Commitment to Accessibility:</strong> DashPoint continuously works to improve accessibility to accommodate all users.
        </p>

        <h3 className="text-2xl font-semibold text-white mb-4">6. Marketing and Advertising Laws</h3>
        <p className="mb-2">
          <strong>Consent for Marketing:</strong> Explicit consent is obtained before sending promotional materials.
        </p>
        <p>
          <strong>Opt-Out Options:</strong> Clear methods are provided for users to opt out of marketing communications.
        </p>
      </section>
    </div>
  );
};

export default Terms;
