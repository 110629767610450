import { Link } from "react-router-dom";
import { useState } from "react";
import SupportModal from "./SupportModal";

const Footer = () => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText("support@dashpoint.uk");
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
  };

  return (
    <footer className="bg-black text-white py-6 px-6">
      <div className="flex flex-col md:flex-row justify-between items-center max-w-7xl mx-auto">
        {/* Left Section: Copyright */}
        <p>© {new Date().getFullYear()} DashPoint. All rights reserved.</p>

        {/* Center Section: Links */}
        <div className="flex gap-4 mt-4 md:mt-0">
          <Link to="/terms" className="text-white hover:underline">
            Terms
          </Link>
          <Link to="/cookie" className="text-white hover:underline">
            Cookie Policy
          </Link>
          <Link to="/privacy" className="text-white hover:underline">
            Privacy Policy
          </Link>
        </div>

        {/* Support Modal */}
        <div className="mt-4 md:mt-0">
          <SupportModal className="border border-white bg-transparent" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
