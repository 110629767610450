import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="absolute top-0 left-0 w-full bg-black bg-opacity-75 text-white py-4 px-6 z-10">
      <div className="flex justify-between items-center max-w-7xl mx-auto">
        {/* Logo links back to the main page */}
        <Link to="/" className="text-xl font-bold text-center md:text-left">
          DashPoint.
        </Link>
        <div className="flex gap-4">
          <a href="https://www.instagram.com/thedashpoint" target="_blank" rel="noopener noreferrer" className="text-white border border-white py-0 px-4 rounded-full hover:bg-white hover:text-black">
            Follow us
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
