import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="bg-black text-gray-300 max-w-7xl mx-auto py-12 px-6 mt-32">
      <h1 className="text-4xl font-bold text-white mb-8">Cookie Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">1. Introduction</h2>
        <p>
          Welcome to DashPoint! This Cookie Policy explains how DashPoint ("we," "us," or "our") uses cookies and similar tracking technologies on our website and mobile application (collectively, the "Services"). By using our Services, you consent to the use of cookies as described in this policy.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">2. What Are Cookies?</h2>
        <p>
          Cookies are small text files that are placed on your device (computer, smartphone, tablet) when you visit a website or use an app. They are widely used to make websites and apps work more efficiently, as well as to provide information to the owners.
        </p>
        <h3 className="text-xl font-semibold text-white mt-4 mb-2">Types of Cookies:</h3>
        <ul className="list-disc list-inside ml-4">
          <li><strong>First-Party Cookies:</strong> Set by DashPoint and used directly by us.</li>
          <li><strong>Third-Party Cookies:</strong> Set by external services (e.g., analytics providers, advertising networks) used by DashPoint.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">3. How We Use Cookies</h2>
        <p>We use cookies and similar technologies for the following purposes:</p>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">3.1. Essential Cookies</h3>
        <p>
          These cookies are necessary for the functioning of our Services. They enable core functionalities such as security, network management, and accessibility.
        </p>
        <ul className="list-disc list-inside ml-4">
          <li>Authentication cookies, session management.</li>
        </ul>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">3.2. Performance and Analytics Cookies</h3>
        <p>
          These cookies help us understand how users interact with our Services by collecting and reporting information anonymously.
        </p>
        <ul className="list-disc list-inside ml-4">
          <li>Google Analytics, Mixpanel.</li>
        </ul>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">3.3. Functionality Cookies</h3>
        <p>
          These cookies allow our Services to remember choices you make (such as language preference) and provide enhanced, personalized features.
        </p>
        <ul className="list-disc list-inside ml-4">
          <li>Language settings, user preferences.</li>
        </ul>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">3.4. Advertising and Targeting Cookies</h3>
        <p>
          These cookies are used to deliver advertisements that are more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and help measure the effectiveness of advertising campaigns.
        </p>
        <ul className="list-disc list-inside ml-4">
          <li>Facebook Pixel, Google Ads.</li>
        </ul>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">3.5. Social Media Cookies</h3>
        <p>
          These cookies enable you to share content from our Services on social networks and may track your browsing activities across other websites.
        </p>
        <ul className="list-disc list-inside ml-4">
          <li>Share buttons from Facebook, Twitter.</li>
        </ul>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">3.6. Similar Technologies in Mobile Apps</h3>
        <p>
          In our iOS application, we use similar technologies to cookies, such as <strong>local storage</strong>, <strong>device identifiers</strong>, and <strong>tracking pixels</strong> to collect and store information about your usage of the app.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">4. What Data We Collect</h2>
        <p>Depending on the types of cookies and technologies used, we may collect the following data:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li><strong>Personal Data:</strong> Such as your name, email address, and contact information (collected through account registration).</li>
          <li><strong>Usage Data:</strong> Information about how you use our Services, including pages visited, features used, and interactions.</li>
          <li><strong>Device Information:</strong> Details about your device, including IP address, browser type, operating system, and device identifiers.</li>
          <li><strong>Location Data:</strong> Information about your geographical location (with your consent).</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">5. Third-Party Cookies and Services</h2>
        <p>
          We may allow third-party service providers to place cookies on your device for the purposes outlined above. These third parties may collect information about your online activities over time and across different websites and apps.
        </p>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">Common Third-Party Services We Use:</h3>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Analytics Providers:</strong> Google Analytics, Mixpanel.</li>
          <li><strong>Advertising Networks:</strong> Google Ads, Facebook Ads.</li>
          <li><strong>Social Media Platforms:</strong> Facebook, Twitter, Instagram.</li>
        </ul>

        <p className="mt-4">
          <strong>Note:</strong> These third parties have their own privacy policies and may use cookies independently of DashPoint.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">6. How to Manage and Control Cookies</h2>
        <p>You have the right to decide whether to accept or reject cookies. You can manage your cookie preferences through the following methods:</p>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">6.1. Through Your Browser Settings</h3>
        <p>
          Most web browsers allow you to control cookies through their settings. You can typically find these settings under "Privacy" or "Security" in your browser's menu.
        </p>
        <ul className="list-disc list-inside ml-4">
          <li>Block or Delete Cookies: You can block or delete cookies at any time. However, please note that disabling cookies may affect the functionality of our Services.</li>
        </ul>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">6.2. Through Our Services</h3>
        <p>
          <strong>Mobile App Settings:</strong> Within the DashPoint iOS app, you can adjust your privacy settings to control data collection and tracking preferences.
        </p>

        <h3 className="text-xl font-semibold text-white mt-4 mb-2">6.3. Opt-Out Tools</h3>
        <ul className="list-disc list-inside ml-4">
          <li>
            <strong>Advertising Opt-Out:</strong> You can opt out of personalized advertising by visiting{' '}
            <a href="[YourAdChoicesLink]" className="text-blue-400 underline">
              [YourAdChoicesLink]
            </a>{' '}
            or using tools like the{' '}
            <a href="https://optout.aboutads.info/" className="text-blue-400 underline">
              Digital Advertising Alliance’s WebChoices tool
            </a>
            .
          </li>
          <li>
            <strong>Analytics Opt-Out:</strong> For Google Analytics, you can install the{' '}
            <a href="https://tools.google.com/dlpage/gaoptout" className="text-blue-400 underline">
              Google Analytics Opt-Out Browser Add-on
            </a>
            .
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">7. Your Rights</h2>
        <p>
          Under the UK GDPR, you have the following rights regarding your personal data:
        </p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li><strong>Right to Access:</strong> You can request access to the personal data we hold about you.</li>
          <li><strong>Right to Rectification:</strong> You can request corrections to any inaccurate or incomplete data.</li>
          <li><strong>Right to Erasure:</strong> You can request the deletion of your personal data under certain conditions.</li>
          <li><strong>Right to Restrict Processing:</strong> You can request the limitation of processing your personal data.</li>
          <li><strong>Right to Data Portability:</strong> You can request the transfer of your personal data to another service provider.</li>
          <li><strong>Right to Object:</strong> You can object to the processing of your personal data for specific purposes.</li>
        </ul>
        <p className="mt-4">
          To exercise these rights, please contact us using the contact information provided below.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">8. Changes to This Cookie Policy</h2>
        <p>
          We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by updating the "Last Updated" date at the top of this policy.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">9. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Cookie Policy or our use of cookies and similar technologies, please contact us at:
        </p>
        <p className="mt-4">
          <strong>Email:</strong>{' '}
          <a href="mailto:support@dashpoint.uk" className="text-blue-400 underline">
            support@dashpoint.uk
          </a>
        </p>
        <p className="mt-2">
          <strong>Address:</strong> [Your Company Address]
        </p>
        <p className="mt-2">
          <strong>Phone:</strong> [Your Contact Number]
        </p>
      </section>
    </div>
  );
};

export default CookiePolicy;
