import React, { useState } from "react";

const SupportModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText("support@dashpoint.uk");
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };

  return (
    <>
      {/* Button to open the modal */}
      <button
        className="border-[#fff] border-2 text-white py-0 px-4 py-0 rounded-full hover:bg-[#fff] hover:text-black"
        onClick={() => setIsOpen(true)}
      >
        Support
      </button>

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center z-50">
          <div className="bg-black text-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-4 text-[#86F04D]">Support</h2>
            <p className="text-gray-300 mb-6">
              Need help? Contact us at:
              <br />
              <span className="text-white font-semibold">support@dashpoint.uk</span>
            </p>
            <div className="flex gap-4">
              {/* Copy Email Button */}
              <button
                className="bg-[#86F04D] text-black py-2 px-4 rounded hover:bg-[#86F04D]/80 flex-1"
                onClick={handleCopyEmail}
              >
                {copied ? "Copied!" : "Copy Email"}
              </button>

              {/* Open Mail App Button */}
              <a
                href="mailto:support@dashpoint.uk"
                className="bg-[#86F04D] text-black py-2 px-4 rounded hover:bg-[#86F04D]/80 flex-1 text-center"
              >
                Open Mail App
              </a>
            </div>
            {/* Close Button */}
            <button
              className="mt-4 bg-gray-800 text-white py-2 px-4 rounded w-full hover:bg-gray-600"
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SupportModal;
